import Link from "next/link";

import {
  homeItems,
  blogItems,
  pageItems,
  dashboardItems,
  memorial,
  presentation,
  activitesEtProgrammes,
} from "../../data/mainMenuData";
import CategoriesMegaMenu from "./CategoriesMegaMenu";
import {
  isActiveParent,
  isActiveLink,
  isActiveParentChaild,
} from "../../utils/linkActiveChecker";
import { useRouter } from "next/router";

const MainMenuJoola = ({ style = "" }) => {
  const router = useRouter();

  return (
    <nav className="menu js-navList">
      <ul className={`menu__nav ${style} -is-active`}>
        <li className={router.pathname === "/home/home_10" ? "current" : ""}
        // style={{fontFamily: 'Museum Bible'}}
        >
          <Link href="/home/home_10">
          ACCUEIL
          </Link>
        </li>
        {/* End Accueil page menu */}
        <li
          className={`${
            isActiveParentChaild(presentation, router.asPath) ? "current" : ""
          } menu-item-has-children menu-boat`}
        >
          <a href="#">
            <span className="mr-10">PRESENTATION</span>
            <i className="icon icon-chevron-sm-down" />
          </a>
          <ul className="subnav">
            {presentation.map((menu, i) => (
              <li
                key={i}
                className={`${
                  isActiveLink(menu.routePath, router.asPath) ? "current" : ""
                } menu-item-has-children menu-boat`}
              >
                <Link href={menu.routePath}
                style={{fontSize: '12px'}}>
                  {menu.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        {/* End Presentation page menu */}

        <li
          className={`${
            isActiveParentChaild(memorial, router.asPath) ? "current" : ""
          } menu-item-has-children menu-boat`}
        >
          <a href="#">
            <span className="mr-10">LE MEMORIAL</span>
            <i className="icon icon-chevron-sm-down" />
          </a>
          <ul className="subnav"
                >
            {memorial.map((menu, i) => (
              <li
                key={i}
                className={`${
                  isActiveLink(menu.routePath, router.asPath) ? "current" : ""
                } menu-item-has-children menu-boat`}
              >
                <Link href={menu.routePath}
                style={{fontSize: '12px'}}>
                  {menu.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        {/* End Memorial page menu */}
        
        <li
          className={`${
            isActiveParentChaild(activitesEtProgrammes, router.asPath) ? "current" : ""
          } menu-item-has-children menu-boat`}
        >
          <a href="#">
            <span className="mr-10">ACTIVITES & PROGRAMMES</span>
            <i className="icon icon-chevron-sm-down" />
          </a>
          <ul className="subnav">
            {activitesEtProgrammes.map((menu, i) => (
              <li
                key={i}
                className={`${
                    isActiveLink(menu.routePath, router.asPath) ? "current" : ""
                } menu-item-has-children menu-boat`}
              >
                <Link href={menu.routePath}
                style={{fontSize: '12px'}}>
                  {menu.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        {/* End Memorial page menu */}

        {/* <li className={router.pathname === "#" ? "current" : ""}>
          <Link href="#">Memorial</Link>
        </li> */}
        {/* End Memorial single menu */}

        {/* <li className={router.pathname === "#" ? "current" : ""}>
          <Link href="/memorial/galerie">Galerie</Link>
        </li> */}
        {/* End Galerie single menu */}

        {/* End Forum  single menu */}
      </ul>
    </nav>
  );
};

export default MainMenuJoola;
