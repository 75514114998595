module.exports = [
  {
    id: 7,
    img: "/img/blog/mediateque/7.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 8,
    img: "/img/blog/mediateque/8.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 9,
    img: "/img/blog/mediateque/9.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 10,
    img: "/img/blog/mediateque/10.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 11,
    img: "/img/blog/mediateque/11.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 12,
    img: "/img/blog/mediateque/12.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 13,
    img: "/img/blog/mediateque/13.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 14,
    img: "/img/blog/mediateque/14.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 15,
    img: "/img/blog/mediateque/15.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 16,
    img: "/img/blog/mediateque/16.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 17,
    img: "/img/blog/mediateque/17.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "food",
  },
  {
    id: 1,
    img: "/img/blog/mediateque/1.png",
    title: "10 European ski destinations you should visit this winter",
    date: "Jan 06, 2023",
    delayAnimation: "100",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "art",
  },
  {
    id: 2,
    img: "/img/blog/mediateque/2.png",
    title: "Booking travel during Corona: good advice in an uncertain time",
    date: "April 06, 2022",
    delayAnimation: "200",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "beaches",
  },
  {
    id: 3,
    img: "/img/blog/mediateque/3.png",
    title: "Where can I go? 5 amazing countries that open right now",
    date: "Jan 06, 2023",
    delayAnimation: "300",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "adventure",
  },
  {
    id: 4,
    img: "/img/blog/mediateque/4.png",
    title: "10 European ski destinations you should visit this winter",
    date: "April 06, 2022",
    delayAnimation: "400",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "explore",
  },
  {
    id: 5,
    img: "/img/blog/mediateque/5.png",
    title: "Booking travel during Corona: good advice in an uncertain time",
    date: "Jan 06, 2023",
    delayAnimation: "100",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "holidays",
  },
  {
    id: 6,
    img: "/img/blog/mediateque/6.png",
    title: "Where can I go? 5 amazing countries that are open right now",
    date: "April 06, 2022",
    delayAnimation: "200",
    details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
    tag: "travel",
  },
  // {
  //   id: 8,
  //   img: "/img/blog/mediateque/8.png",
  //   title: "Booking travel during Corona: good advice in an uncertain time",
  //   date: "April 06, 2022",
  //   delayAnimation: "200",
  //   details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
  //   tag: "holidays",
  // },
  // {
  //   id: 9,
  //   img: "/img/blog/mediateque/9.png",
  //   title: "Where can I go? 5 amazing countries that are open right now",
  //   date: "April 06, 2022",
  //   delayAnimation: "300",
  //   details: `For decades, travelers have been escaping to the Catskills — a mountainous region in upstate New York — whenever they’ve needed a reset.`,
  //   tag: "park",
  // },
  // {
  //   id: 10,
  //   img: "/img/blog/1.png",
  //   title: "10 European ski destinations you should visit this winter",
  //   date: "April 06, 2022",
  //   delayAnimation: "100",
  //   details: `Ut enim ad minim veniam, quis nostrud exerc ullamco laboris nisi ut aliquip.`,
  //   tag: "",
  // },
  // {
  //   id: 11,
  //   img: "/img/blog/2.png",
  //   title: "Where can I go? 5 amazing countries that are open right now",
  //   date: "April 06, 2022",
  //   delayAnimation: "200",
  //   details: `Ut enim ad minim veniam, quis nostrud exerc ullamco laboris nisi ut aliquip.`,
  //   tag: "",
  // },
];
